import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationExtras, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';

import { SurveyModule } from 'survey-angular-ui';

import { EventBus } from 'framework';

import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { NavigationService } from './services/navigation/navigation.service';
import { ProposalSessionService } from './services/proposal-session/proposal-session.service';

import { SolisSessionService } from './services/session/solis-session.service';
import { SolisDestinationPage } from './services/session/solis-session-service.enums';

import { GatewayApiClient } from './services/gateway-api-client/gateway-api-client.service';
import {
  ProductSummarySearchCriteriaDto,
  FilterCategoryDto,
  AllowableProductDto,
  ProductSummaryDto,
  DataSource,
  FilterCodes,
  ProductSortType
} from 'api-datamart';
import { FirmDto, SettingDto, UserDto, FirmType, SettingEntityType } from 'api-infrastructure';

import { SettingsSearchBuilder } from 'api-infrastructure';
import { WebHelper } from 'framework';

import { firmTypeToSettingEntityTypeMap } from './constants/mappings';
import { EventNames } from './constants/event-names';
import { SessionKeys } from './constants/session-keys';

import { ThemeChangeConfiguration } from './models/website.models';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, ToastModule, SelectButtonModule, FormsModule, ButtonModule, ProgressSpinnerModule, SurveyModule, BlockUIModule]
})
export class AppComponent implements OnInit, OnDestroy {
  initError: string = '';
  userName: string = '';
  advisorFirmsForDirectLogin: FirmDto[] = [];
  partnerFirmsForDirectLogin: FirmDto[] = [];
  selectedFirm: FirmDto | null = null;

  private subscriptionGroup: Subscription = new Subscription();
  public blockDocument: boolean = false;
  public blockMessage: string = '';
  public errors: string[] = [];

  get displayInitError(): string {
    return WebHelper.formatErrorMessage(this.initError);
  }

  constructor(
    public solisSessionService: SolisSessionService,
    private apiClient: GatewayApiClient,
    private eventBus: EventBus,
    private navigationService: NavigationService,
    private breadcrumbService: BreadcrumbService,
    private proposalSessionService: ProposalSessionService
  ) {
    // If cookies are disabled, notify user and do not initialize.
    if (!navigator.cookieEnabled) {
      this.initError = 'Cookies are not enabled on your browser.  Please enable cookies in your browser settings.';
      return;
    }
    // WE MUST INITIALIZE BREADCRUMBS OR THEY WILL NOT WORK
    this.navigationService.initialize();
    this.breadcrumbService.initialize();
  }

  // Set up listener for theme changes actions.
  @HostListener('window:message', ['$event']) updateThemePostMessage(event: MessageEvent<ThemeChangeConfiguration>) {
    this.updateThemeMessage(event);
  }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize(): Promise<void> {
    if (!navigator.cookieEnabled || !this.solisSessionService.user) {
      return;
    }

    // TODO: Check to make sure the session is valid and show error if not
    // ex: Check that user is associated with at least one firm, etc
    // UPDATE: This todo is a placement for future work that may be beneficial
    // but for now can be ignore.
    if ((this.solisSessionService.user.firms?.length ?? 0) === 0) {
      this.initError += 'User is not associated with any firms';
      return;
    }

    this.userName = this.solisSessionService.user.userName!;
    const activeFirm = this.solisSessionService.getActiveFirm();

    this.initializeSubscriptions();
    this.applyUserPreferences();

    if (this.solisSessionService.launch) {
      // When being launched from an external source, a firm code will always be provided
      // and we will always be using this to set the active firm
      // Set active firm from launch payload
      if (!activeFirm) {
        this.solisSessionService.setActiveFirm(
          this.solisSessionService.launch!.payload!.configuration!.firmDtccMemberCode!,
          this.solisSessionService.launch!.payload!.configuration!.firmType!
        );
      }

      const settings = await this.resolveSettings();

      this.solisSessionService.initializeSettings(settings);

      const scripts = this.solisSessionService.getSettingValue<string[]>('CustomScripts', false) ?? [];
      this.generateCustomScripts(scripts);

      await this.setHomePathByPayloadDestination(this.solisSessionService.launch!.payload!.configuration!.destinationPage);

      // If missing user data, redirect user to account-setup page to fill in required data
      if (!this.resolveRequiredUserData()) {
        await this.navigationService.navigate(['/account-setup'], { replaceUrl: true });
        this.solisSessionService.finishAppInitialize();
        return;
      }

      let extras: NavigationExtras = { replaceUrl: true };
      if (this.navigationService.getHomePath() === '') {
        extras.onSameUrlNavigation = 'reload';
      }

      await this.performPostLoginRedirect(extras);
      this.solisSessionService.finishAppInitialize();
    } else {
      // This is not an external launch, meaning it's PI direct login
      // Check to see how many firms the user belongs to
      // If we get to this point, user.firms is an array
      if (!activeFirm) {
        if (this.solisSessionService.user.firms!.length === 1) {
          // If it's just one, set it as the active firm and continue with init
          this.solisSessionService.setActiveFirm(
            this.solisSessionService.user.firms![0].dtccMemberCode || '',
            this.solisSessionService.user.firms![0].type!
          );
        } else {
          // User belongs to more than one firm, display picker
          // Since firms are required in user, if user exists then firms array does too.
          this.advisorFirmsForDirectLogin = this.solisSessionService.user.firms!.filter((f) => f.type! === FirmType.DtccDistributor);
          this.partnerFirmsForDirectLogin = this.solisSessionService.user.firms!.filter((f) => f.type! !== FirmType.DtccDistributor);
          return;
        }
      }

      this.finishDirectLoginInit({ onSameUrlNavigation: 'reload' });
    }
  }

  ngOnDestroy(): void {
    this.subscriptionGroup.unsubscribe();
  }

  initializeSubscriptions(): void {
    const userPreferencesChangedSubscription = this.eventBus.on(EventNames.userPreferencesChanged, () => {
      this.applyUserPreferences();
    });
    this.subscriptionGroup.add(userPreferencesChangedSubscription);

    const blockUIEnableSubscription = this.eventBus.on<string>(EventNames.blockUI, (message: string) => {
      this.blockDocument = true;
      this.blockMessage = message;
    });
    this.subscriptionGroup.add(blockUIEnableSubscription);

    const blockUIDisableSubscription = this.eventBus.on(EventNames.unblockUI, () => {
      this.blockDocument = false;
      this.blockMessage = '';
    });
    this.subscriptionGroup.add(blockUIDisableSubscription);
  }

  applyUserPreferences(): void {
    // Theme
    const body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-theme', this.solisSessionService.userPreferences.theme!);
  }

  onFirmSelected(): void {
    this.finishDirectLoginInit({ onSameUrlNavigation: 'reload' });
  }

  private async finishDirectLoginInit(extras?: NavigationExtras | undefined): Promise<void> {
    // If we're in firm selection mode, set active firm
    if (this.advisorFirmsForDirectLogin.length > 0 || this.partnerFirmsForDirectLogin.length) {
      // Make sure to reset firm selections so they won't render after a page refresh
      this.advisorFirmsForDirectLogin = [];
      this.partnerFirmsForDirectLogin = [];

      // Set active firm
      this.solisSessionService.setActiveFirm(this.selectedFirm!.dtccMemberCode!, this.selectedFirm!.type!);
    }

    // With active firm, grab settings and initialize application.
    const settings = await this.resolveSettings();
    this.solisSessionService.initializeSettings(settings);

    const landingPageRoute = this.solisSessionService.getSettingValue<SolisDestinationPage>('IX.FirmLandingPage', false);
    if (landingPageRoute !== null) {
      this.setHomePathByPayloadDestination(landingPageRoute);
    }

    // If missing user data, redirect user to account-setup page to fill in required data
    if (!this.resolveRequiredUserData()) {
      // Redirect user to new page to fill in required user data.
      await this.navigationService.navigate(['/account-setup']);
      this.solisSessionService.finishAppInitialize();
      return;
    }

    await this.performPostLoginRedirect(extras);

    this.solisSessionService.finishAppInitialize();
  }

  private async performPostLoginRedirect(extras?: NavigationExtras | undefined): Promise<void> {
    if (this.solisSessionService.postLoginRoute && this.solisSessionService.postLoginRoute !== '/') {
      // If a post login route exists (for instance due to a page refresh), navigate to that page.
      await this.navigationService.navigateByUrl(this.solisSessionService.postLoginRoute, extras);
    } else {
      // This will have been set during solisSessionService init if we're restoring a previous session
      const restoreSessionUrl = this.solisSessionService.localSession.get<string>(SessionKeys.lastSessionLocation);

      if (!restoreSessionUrl) {
        await this.navigationService.navigateHome(extras);
      } else {
        // Restoring a previous session is a one-time thing, so remove value from session before continuing
        this.solisSessionService.localSession.set(SessionKeys.lastSessionLocation, null);

        // Navigate to wherever the session ended
        await this.navigationService.navigateByUrl(restoreSessionUrl, extras);
      }
    }
  }

  private async resolveSettings(): Promise<SettingDto[]> {
    const firm = this.solisSessionService.getActiveFirm()!;

    const searchCriteria = new SettingsSearchBuilder()
      .withTags('solis_init')
      .withEntity(SettingEntityType.Platform, this.solisSessionService.activePlatformCode)
      .withEntity(firmTypeToSettingEntityTypeMap.get(firm.type!)!, firm.dtccMemberCode!)
      .build();

    const settingsResponse = await this.apiClient.resolveSettings(searchCriteria);

    return settingsResponse.result!;
  }

  private resolveRequiredUserData(): boolean {
    const requiredUserData = this.solisSessionService.getSettingValue<string[]>('Website.RequiredUserFields', false) ?? [];
    for (const key of requiredUserData) {
      if ((this.solisSessionService.user![key as keyof UserDto] ?? null) === null) {
        return false;
      }
    }
    return true;
  }

  async getProductInfo(): Promise<ProductSummaryDto> {
    const response = await this.apiClient.searchProductSummaries(
      new ProductSummarySearchCriteriaDto({
        dataSources: [DataSource.Ppfa],
        filters: [
          new FilterCategoryDto({
            code: FilterCodes.FirmDtccMemberCode,
            values: [this.solisSessionService.getActiveFirm()!.dtccMemberCode!]
          }),
          new FilterCategoryDto({ code: FilterCodes.FirmType, values: [this.solisSessionService.getActiveFirm()!.type!] })
        ],
        allowableProducts: [new AllowableProductDto({ cusip: this.solisSessionService.launch!.payload?.proposal?.cusip })]
      }),
      1000,
      0,
      ProductSortType.ProductName
    );
    if (response?.result?.results[0]) {
      return response?.result?.results[0];
    } else {
      this.errors.push(`No product results found for cusip: ${this.solisSessionService.launch!.payload?.proposal?.cusip}`);
      throw Error(WebHelper.formatErrorMessage(this.errors[0]));
    }
  }

  private async setHomePathByPayloadDestination(destinationPage?: SolisDestinationPage | null): Promise<void> {
    destinationPage = this.checkForRouteOverride(destinationPage);

    // Look for additional arguments passed in the launch payload and process them
    switch (destinationPage) {
      case SolisDestinationPage.ProductSelection:
        this.navigationService.setHomePath('products');
        break;
      case SolisDestinationPage.ProductDetails:
        const platformProposalId = this.solisSessionService.launch?.payload?.platform?.platformProposalId!;
        const proposalDetailId = this.solisSessionService.launch?.payload?.proposal?.proposalDetailId!;
        const proposalDetail = await this.proposalSessionService.processProposalToSessionById(platformProposalId, proposalDetailId);

        if (proposalDetail) {
          this.navigationService.setHomePath(`products/${proposalDetail.product!.cusip}-${proposalDetail.product!.productCode}/details`);
        }

        break;
      case SolisDestinationPage.ProductCompare:
        this.navigationService.setHomePath('products/compare');
        break;
      case SolisDestinationPage.ProductResearch:
        let productCode = this.solisSessionService.launch!.payload?.proposal?.productCode;
        if (!productCode) {
          const product = await this.getProductInfo();
          productCode = product.productCode;
        }
        this.navigationService.setHomePath(`products/${this.solisSessionService.launch!.payload?.proposal?.cusip}-${productCode}/research`);
        break;
      case SolisDestinationPage.ProductReplacement:
        this.navigationService.setHomePath('products/replacement');
        break;
      case SolisDestinationPage.Dashboard:
        this.navigationService.setHomePath('dashboard');
        break;
      case SolisDestinationPage.AnnuityContract:
        this.navigationService.setHomePath(`annuity-contracts/${this.solisSessionService.launch!.payload?.contract?.contractNumber}`);
        break;
      case SolisDestinationPage.Inforce:
        this.navigationService.setHomePath('inforce-management');
        break;
      case SolisDestinationPage.ProposalSummary:
        this.navigationService.setHomePath('platform-proposal-summary');
        break;
      case SolisDestinationPage.AccountOpening:
        this.navigationService.setHomePath('account-opening');
        break;
      case SolisDestinationPage.InsuranceWorkbench:
        this.navigationService.setHomePath('insurance-workbench');
        break;
      case SolisDestinationPage.LandingPage:
        this.navigationService.setHomePath('');
        break;
      case SolisDestinationPage.CaseProfile:
        this.navigationService.setHomePath(`case-profiles/${this.solisSessionService.launch!.payload?.proposal?.proposalCaseId}`);
        break;
      case SolisDestinationPage.ProposalStrategy:
        this.navigationService.setHomePath(`proposal-strategy`);
        break;
      case SolisDestinationPage.IXHub:
        this.navigationService.setHomePath(`ixhub`);
        break;
    }
  }

  checkForRouteOverride(destinationPage?: SolisDestinationPage | null): SolisDestinationPage | null | undefined {
    const overrideSettings =
      this.solisSessionService.getSettingValue<{ original: SolisDestinationPage; redirect: SolisDestinationPage }>(
        'Launch.DestinationPage.Override',
        false
      ) ?? null;

    if (overrideSettings?.redirect && overrideSettings?.original === destinationPage) {
      return overrideSettings.redirect;
    }

    return destinationPage;
  }

  /* istanbul ignore next */
  private generateCustomScripts(scripts: string[]): void {
    scripts.forEach((scriptUrl) => {
      const node = document.createElement('script');
      node.src = scriptUrl;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  private updateThemeMessage(event: MessageEvent<ThemeChangeConfiguration>) {
    if ((event.data.type ?? '').toLowerCase() === 'theme') {
      const theme = (event.data.theme ?? '').toLowerCase();
      // Do not do the theme call if it's not currently light or dark
      if (['light', 'dark'].indexOf(theme) > -1) {
        this.solisSessionService.updateTheme(theme);
      }
    }
  }
}
